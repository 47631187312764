.round.round--collectiefgeheugen {
    display: grid;
    grid-template-rows: auto 2vh 50vh;
}

.round.round--collectiefgeheugen .current-question {
    height: 100%;
    display: flex;
    justify-content: center;
    width: 100%;
}

.round.round--collectiefgeheugen .current-question img {
    max-width: 100vw;
    max-height: 68vh;
}

.overview {
    display: grid;
    grid-template-columns: repeat(15, auto);
    align-items: end;
    margin-bottom: 20px;
}

.overview .list-questions {
    display: contents;
}

.overview .question {
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 1.5em;
}

.overview .question.question--scoring {
    margin-bottom: 15px;
}

.overview .question.question--active {
    background-color: #fcb617;
    color: #800000;
    border-radius: 50%;
    font-weight: bold;
}

.overview-wrapper {
    display: grid;
    grid-template-rows: 50% 50%;
    background: rgb(66, 0, 0);
    background: linear-gradient(180deg, rgba(66, 0, 0, 1) 0%, rgb(190 0 0) 100%);
}

.round.round--collectiefgeheugen .separator {
    background: #000080;
}

.question-selector {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
    width: 100%;
}

/* Style individual questions */
.question {
    display: flex;
    justify-content: center;
    align-items: center;
}

.question-selector .question img {
    width: 100%;
}

.question-selector .question.selected img {
    filter: grayscale(1);
}

/* Make the iframes responsive */
.question iframe {
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
    border: none;
}

.round.round--collectiefgeheugen.play {
    grid-template-columns: 60vw 1vw auto;
    grid-template-rows: unset;
}

.round.round--collectiefgeheugen.play .player-standings .player-score:not(.active) {
    display: none;
}

.round.round--collectiefgeheugen.play .question-answers {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 40px;
}

.round.round--collectiefgeheugen.play .question-answers .answer-part {
    display: flex;
    gap: 20px;
    align-items: center;
}

.round.round--collectiefgeheugen.play .question-answers .answer-part__score {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    font-weight: bold;
    color: darkred;
    background: darkred;
    /* Red gradient */
    border-radius: 50px;
    width: 100px;
    height: 50px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    /* Adds the shadow effect */
    text-align: center;
}

.answer-part__answer {
    font-size: 1.5em;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    filter: blur(10px);
}

.answer-part.answered .answer-part__answer {
    filter: unset;
}

.round.round--collectiefgeheugen.play .question-answers .answer-part.answered .answer-part__score {
    background: radial-gradient(circle, #0000ff, #000080);
    color: white;
}

.round.round--collectiefgeheugen.play .question-answers .answer-part.answered {
    text-decoration: unset;
}

.fullscreen-video-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.hidden {
    display: none;
}