.round.round--finale {
    display: grid;
    grid-template-rows: auto 2vh 50vh;
}

.round.round--finale .current-question {
    height: 100%;
    display: flex;
    justify-content: center;
    width: 100%;
}

.round.round--finale .current-question img {
    max-width: 100vw;
    max-height: 68vh;
}

.round.round--finale .separator {
    background: #000080;
}

.round.round--finale.play {
    grid-template-columns: 60vw 1vw auto;
    grid-template-rows: unset;
}

.round.round--finale.play .player-standings .player-score.not-in-finals {
    display: none;
}

.round.round--finale.play .question-answers {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 40px;
}

.round.round--finale.play .question-answers .answer-part {
    display: flex;
    gap: 20px;
    align-items: center;
}

.round.round--finale.play .question-answers .answer-part__score {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    font-weight: bold;
    color: darkred;
    background: darkred;
    /* Red gradient */
    border-radius: 50px;
    width: 100px;
    height: 50px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    /* Adds the shadow effect */
    text-align: center;
}

.round.round--finale.play .question-answers .answer-part.answered .answer-part__score {
    background: radial-gradient(circle, #0000ff, #000080);
    color: white;
}

.round.round--finale.play .question-answers .answer-part.answered {
    text-decoration: unset;
}

.endscreen {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    flex-direction: column;
}

.endscreen .winner {
    font-size: 10rem;
    font-family: "BarlowCondensed", sans-serif;
    color: white;
}