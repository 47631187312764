:root {
  --bs-btn-border-color: var(--color-primary);
}

@font-face {
  font-family: "BarlowCondensed";
  src: url("./assets/fonts/BarlowCondensed-ExtraBoldItalic.otf") format("opentype");
  font-weight: 800; /* ExtraBold */
  font-style: italic;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.startscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(./images/startscreen.jpeg);
  background-size: cover;
  background-position: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.page-title {
  font-size: xx-large;
}

.page-actions {
  display: flex;
  gap: 16px;
}

.round {
  background-color: #800000;
  height: 100vh;
  width: 100vw;
}

.question-container {
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #fff;
}

.question-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.question-label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.question-input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
}

.question-input[type="checkbox"] {
  width: auto;
  margin-top: 0;
}

.player-standings {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}

.player-standings .name {
  font-size: 1.5em;
  font-weight: bold;
  color: white;
}

.player-score {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.player-score .name {
  font-size: 1.5em;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  text-align: center;
}

.player-score .score {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: bold;
  color: white;
  background: radial-gradient(circle, #ff0000, #800000);
  /* Red gradient */
  border-radius: 50px;
  width: 100px;
  height: 50px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  /* Adds the shadow effect */
  text-align: center;
}

.player-score.active .score {
  background: radial-gradient(circle, #0000ff, #000080);
  /* Blue gradient */
}

.round-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #800000;
  /* Match the background of the round screen */
}

.round.hidden-content {
  opacity: 0;
  /* Keep content hidden during video playback */
}

.fullscreen-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 10;
  opacity: 1;
  transition: opacity 1.5s ease;
}

.btn-primary {
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #c0c0c0;
  --bs-btn-disabled-border-color: #c0c0c0;
}

.btn-link {
  background: none;
  border: none;
  color: #007bff; /* Use your primary color */
  text-decoration: unset;
  cursor: pointer;
  padding: 0; /* Remove padding to match the appearance of a link */
  font-size: inherit; /* Inherit font size from parent */
}

.btn-link:hover {
  color: #0056b3; /* Darker shade for hover effect */
  text-decoration: underline;
  background-color: unset;
}

.btn-link:disabled {
  color: #6c757d; /* Gray color for disabled state */
  text-decoration: none; /* No underline for disabled */
  cursor: not-allowed;
}