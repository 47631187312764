.round.round--galerij.play {
    display: flex;
}

.round.round--galerij.play .question-galerij {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.round.round--galerij.play .question-galerij img {
    max-width: 100vw;
    max-height: 100vh;
}