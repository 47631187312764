/* Apply custom width and height rules */
.media-select-modal.modal-dialog {
    max-width: 70vw;
    /* Make modal wider */
    width: 100%;
    /* Ensure it uses full width up to max-width */
}

.media-select-modal .modal-content {
    max-height: 90vh;
    /* Limit height to 90% of the viewport */
    overflow-y: auto;
    /* Enable scrolling when content overflows */
}

.modal-body {
    overflow-y: auto;
    /* Scrollable content inside the body */
}