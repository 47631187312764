.round.round--puzzel {
    display: grid;
    grid-template-rows: auto 2vh 50vh;
}

.round.round--puzzel .current-question {
    height: 100%;
    display: flex;
    justify-content: center;
    width: 100%;
}

.round.round--puzzel .current-question img {
    max-width: 100vw;
    max-height: 68vh;
}

.question-selector {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
    width: 100%;
}

/* Style individual questions */
.question {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Make the iframes responsive */
.question iframe {
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
    border: none;
}

.round.round--puzzel.play {
    grid-template-columns: 59vw 1vw 40vw;
    grid-template-rows: 59vh 1vh 40vh;
}

.round.round--puzzel.play .player-standings {
    grid-column-start: span 3;
    grid-row: 3;
}

.round.round--puzzel.play .question-answers {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 40px;
    grid-column: 3;
    grid-row: 1;
}

.round.round--puzzel.play .question-answers .answer-part {
    display: flex;
    gap: 20px;
    align-items: center;
}

.round.round--puzzel.play .question-answers .answer-part__score {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    font-weight: bold;
    color: darkred;
    background: darkred;
    /* Red gradient */
    border-radius: 50px;
    width: 100px;
    height: 50px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    /* Adds the shadow effect */
    text-align: center;
}

.answer-part__answer {
    font-size: 1.5em;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    filter: blur(10px);
}

.answer-part.answered .answer-part__answer {
    filter: unset;
}

.round.round--puzzel.play .question-answers .answer-part.answered .answer-part__score {
    background: radial-gradient(circle, #0000ff, #000080);
    color: white;
}

.round.round--puzzel.play .question-answers .answer-part.answered {
    text-decoration: unset;
}

.question-puzzle {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 40px;
    gap: 20px;
}

.question-puzzle .puzzle-piece {
    background-color: #900000;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -7px 7px 5px 0px #00000040;
    font-size: 1.2em;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    text-align: center;
}

.round.round--puzzel.play .question-answers .answer-part.answer-part-0.answered .answer-part__score,
.question-puzzle .puzzle-piece.answer-part-0.done {
    background: radial-gradient(circle, #33508c, #2f6ff3);
    color: white;
}

.round.round--puzzel.play .question-answers .answer-part.answer-part-1.answered .answer-part__score,
.question-puzzle .puzzle-piece.answer-part-1.done {
    background: radial-gradient(circle, whitesmoke, #cbcbe5);
    color: darkblue;
}

.round.round--puzzel.play .question-answers .answer-part.answer-part-2.answered .answer-part__score,
.question-puzzle .puzzle-piece.answer-part-2.done {
    background: radial-gradient(circle, #3737be, darkblue);
    color: white;
}