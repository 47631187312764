/* General Layout */
.pageContent.quiz-master-screen {
  background-color: #b50000;
  min-height: 100vh;
}

.quiz-master {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 8px;
}

.quiz-master-screen .round-title {
  text-align: center;
  width: 100%;
  color: white;
  font-size: 50px;
  font-family: "BarlowCondensed", sans-serif;
  border-bottom: 2px solid white;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.question-admin {
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.question-admin__question,
.question-admin__answer {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.question-admin .label {
  font-weight: bold;
  font-size: 18px;
}

.quiz-master-screen .actions .btn.action--correct {
  background-color: green;
  border-color: green;
  color: white;
}

.quiz-master-screen .actions .btn {
  text-transform: capitalize;
  min-height: 60px;
  background-color: white;
  color: #b50000;
  font-weight: bold;
  border-color: white;
}

.quiz-master-screen .actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.loading,
.error,
.no-question {
  color: #b00;
  font-weight: bold;
}

/* Player List */
.player-list {
  list-style-type: none;
  padding: 0;
}

.player {
  padding: 8px 16px;
  margin: 4px 0;
  background: #fff;
  border-radius: 4px;
}

.active-player {
  background: #ffeb3b;
  font-weight: bold;
}

/* Question & Answer Styles */
.question-text,
.answer-text {
  font-size: 18px;
  font-weight: bold;
}

.answer-parts {
  list-style-type: none;
  padding: 0;
}

.answer-part {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.answered {
  text-decoration: line-through;
  color: #999;
}

/* Buttons */
button {
  margin: 4px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.btn-success {
  background: #4caf50;
  color: white;
}

.btn-primary {
  background: #2196f3;
  color: white;
}

.btn-warning {
  background: #ff9800;
  color: white;
}

/* Highlight already selected questions */
.already-selected {
  color: #999;
  text-decoration: line-through;
}

ul.select-questions {
  padding-left: 0;
}

.select-questions li {
  margin: 8px 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}