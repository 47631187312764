.round-title-wrapper {display: flex;flex-direction: column;height: 100%;}

.round-title-wrapper .round-title {
    flex: 1;
    display: flex;
    font-size: 10rem;
    text-align: center;
    margin-bottom: 20px;
    font-family: "BarlowCondensed", sans-serif;
    justify-content: center;
    align-items: center;
    color: white;
}

.round-title-wrapper .player-standings {
    margin-bottom: 10vh;
}