/* Main container for the quiz detail page */
.quiz-detail-container {
    text-align: center;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Title styling */
  .quiz-detail-title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  /* Back button styling */
  .quiz-detail-back-button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .quiz-detail-back-button:hover {
    background-color: #0056b3;
  }
  