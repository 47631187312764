/* Main container for the quiz list */
.quiz-list-container {
    margin-top: 2rem;
    padding: 1rem;
}

/* Page title */
.quiz-list-title {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
}

/* Container for quiz cards */
.quiz-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
}

/* Quiz card styling */
.quiz-card {
    width: 18rem;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
}

.quiz-card:hover {
    transform: scale(1.05);
}

/* Add quiz card styling */
.add-quiz-card {
    width: 18rem;
    text-align: center;
    cursor: pointer;
    border: 2px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease;
}

.add-quiz-card:hover {
    transform: scale(1.05);
}

/* Add quiz plus sign */
.add-quiz-plus {
    font-size: 3rem;
    margin: 0;
}

/* Add quiz text */
.add-quiz-text {
    margin: 0;
    font-size: 1rem;
    color: #666;
}