.player-standings {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    width: 100%;
}

.player-standings .name {
    font-size: 1.5em;
    font-weight: bold;
    color: white;
}

.player-score {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.player-score .name {
    font-size: 1.5em;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    text-align: center;
}

.player-score .score {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    font-weight: bold;
    color: white;
    background: radial-gradient(circle, #ff0000, #800000);
    /* Red gradient */
    border-radius: 50px;
    width: 100px;
    height: 50px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    /* Adds the shadow effect */
    text-align: center;
}

.player-score.active .score {
    background: radial-gradient(circle, #0000ff, #000080);
    /* Blue gradient */
}
