.loginPrompt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  /* Adjust to center vertically */
  text-align: center;
}

.loginPrompt h3 {
  margin-bottom: 20px;
}

.pageContent {
  padding: 20px;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}