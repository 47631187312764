.round.round--369 {
    display: grid;
    grid-template-rows: auto 2vh 30vh;
}

.round.round--369 .current-question {
    height: 100%;
    display: flex;
    justify-content: center;
    width: 100%;
}

.round.round--369 .current-question img {
    max-width: 100vw;
    max-height: 68vh;
}

.round.round--369 .overview {
    display: grid;
    grid-template-columns: repeat(12, auto);
    align-items: end;
    margin-bottom: 20px;
}

.round.round--369 .overview .list-questions {
    display: contents;
}

.round.round--369 .overview .question {
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 1.5em;
}

.round.round--369 .overview .question.question--scoring {
    margin-bottom: 15px;
}

.round.round--369 .overview .question.question--active {
    background-color: #fcb617;
    color: #800000;
    border-radius: 50%;
    font-weight: bold;
}

.round.round--369 .overview-wrapper {
    display: grid;
    grid-template-rows: 50% 50%;
    background: rgb(66, 0, 0);
    background: linear-gradient(180deg, rgba(66, 0, 0, 1) 0%, rgb(190 0 0) 100%);
}

.round.round--369 .separator {
    background: linear-gradient(90deg, rgba(190, 0, 0, 1) 0%, rgb(88 0 0) 50%, rgba(190, 0, 0, 1) 100%);
}
