.media-screen-content {
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 20px;
  }
  
  .media-screen-content h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
 
  .media-screen-content .media-browser {
    margin-top: 20px;
  }
  