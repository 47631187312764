.media-browser {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.breadcrumb {
  margin-bottom: 20px;
  font-size: 16px;
}

.breadcrumb .path {
  font-weight: bold;
  margin-right: 10px;
}

.breadcrumb-item {
  cursor: pointer;
  color: #007bff;
}

.breadcrumb-item:hover {
  text-decoration: underline;
}

.upload-section {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.upload-label {
  cursor: pointer;
  color: #0056b3;
  text-decoration: unset;
  font-size: inherit;
}

.upload-label:hover {
  text-decoration: underline;
}

#file-upload {
  display: none;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

button:hover {
  background-color: #0056b3;
}

.media-browser-content {
  display: flex;
  gap: 20px;
}

.media-browser-folder-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.media-list {
  list-style: none;
  padding: 0;
}

.media-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 5px;
  cursor: pointer;
  background-color: #f9f9f9;
}

.media-item:hover {
  background-color: #f1f1f1;
}

.media-item.selected {
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.delete-button {
  background-color: #dc3545;
  color: white;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  border: none;
}

.delete-button:hover {
  background-color: #c82333;
}

.file-preview {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.image-preview {
  max-width: 300px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.error {
  color: red;
  font-size: 14px;
  margin-top: 20px;
}